import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import {
  UnderwritingDetailsUserResult,
  UnderwritingUserViewResult,
  UpdateChangeTag,
  UpdateUnderwritingPayload
} from '../../../network/UnderwritingActions';
import { Product } from '../../../types/common';
import { PostError } from '../../../network/AdminRequest';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';

export type UpdateUnderwritingDialogPayload = {
  userId: number
  decisionId: number | null
} | null;

const rejectReasonsValues = [
  "You have either very few loans or too many loans with delinquencies",
  "Your Credit History of making payments on time was not satisfactory",
  "The date since most recent delinquency is too recent",
  "Collection action or judgment",
  "Foreclosure or repossession",
  "Bankruptcy",
  "Too many accounts were opened recently",
  "Too many recent inquiries",
  "Too high credit utilization",
  "We were not able to verify identity information you provided",
  "Income insufficient for amount of credit requested",
  "Excessive obligations in relation to income",
  "Your Income is insufficient to sustain payments on the amount of credit requested"
];

const APPROVED_PRODUCT = [
  "BalanceTransfer Contract", "BalanceTransferLine",
  "BalanceTransfer Offer", "BillPay Contract",
  "BillPayLine", "BillPay Offer",
]

export const UpdateUnderwritingDialog: FC<Props> = ({
  payload,
  hideDialog,
  userId,
}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { underwritingActions } = useInjection();
  const decisionId = payload?.decisionId;

  const [changeTag, setChangeTag] = useState<UpdateChangeTag>("ChangeToApprove");

  const [product, setProduct] = useState<Product>("BalanceTransfer");
  const [isOffer, setIsOffer] = useState<boolean | undefined>(undefined);
  const [limit, setLimit] = useState<string>("");
  const [sendRejectionLetter, setSendRejectionLetter] = useState<boolean>(false);
  const [rejectReasons, setRejectReasons] = useState<string[]>([]);
  const [comment, setComment] = useState<string>("");
  const [isSyncOnboardingFlowWithProduct, setIsSyncOnboardingFlowWithProduct] = useState<boolean>(false);
  const [offerCrossSell, setOfferCrossSell] = useState<boolean>(false);
  const [apr, setApr] = useState<string>("");
  const [lastUnderwritingProduct, setLastUnderwritingProduct] = useState<string>("");

  const [error, setError] = useState<PostError>(null);

  const [userData, setUserData] = useState<UnderwritingDetailsUserResult | null>(null);

  const setupDefaultLimitAndApr = () => {
    if (userData !== null && changeTag === "ChangeToApprove") {
      const { bt_limit, bp_limit, bt_apr } = userData;

      if (product === "BalanceTransfer") {
        const limit = bt_limit === null || bt_limit === undefined
          ? ""
          : currency(bt_limit, { fromCents: true }).value.toString();
        setLimit(limit);
        if (bt_apr !== null && bt_apr !== undefined) {
          setApr(bt_apr.toString());
        }
      }

      if (product === "BillPay") {
        const limit = bp_limit === null || bp_limit === undefined
          ? ""
          : currency(bp_limit, { fromCents: true }).value.toString();

        setLimit(limit);
        setApr("");
      }
    }
  }

  useEffect(() => {
    setIsOffer(isOffer ?? false);
    setChangeTag("ChangeToApprove");
    setLimit("");
    setRejectReasons([]);
    setSendRejectionLetter(false)
    setProduct("BalanceTransfer");
    setError(null);
    setComment("");
    setupDefaultLimitAndApr();
    setIsSyncOnboardingFlowWithProduct(false);
    setOfferCrossSell(false);
  }, [payload]);

  useEffect(() => {
    if (lastUnderwritingProduct.includes("Offer")) {
      setIsOffer(true);
    } else {
      setIsOffer(false);
    }
  }, [lastUnderwritingProduct]);

  useEffect(setupDefaultLimitAndApr, [product, changeTag]);

  useEffect(() => {
    if (userId !== undefined) {
      underwritingActions.fetchUnderwritingDetailsData(userId)
        .then((underwritingData) => {
          const approvedItems = underwritingData.filter((i) =>
            APPROVED_PRODUCT.includes(i.product));

          if (approvedItems.length !== 0) {
            const data = underwritingData[0];

            setLastUnderwritingProduct(data.product);
            setUserData(data);
          }
        });
    }
  }, [userId, payload]);

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId } = payload;

    console.log(userId);

    const decision = changeTag === "ChangeToApprove"
      ? {
        tag: "ChangeToApprove",
        contents: {
          isOffer,
          baseLimit: limit === "" ? null : currency(limit).intValue,
          isSyncOnboardingFlowWithProduct,
          product,
          decisionComment: comment,
          apr: parseFloat(apr),
        }
      }
      : {
        tag: "ChangeToReject",
        contents: {
          sendRejectionLetter,
          rejectReasons,
          comment,
          offerCrossSell,
        }
      }

    const _payload = {
      userId: parseInt(userId.toString()),
      decision,
      decisionId,
    }

    console.log(_payload);

    const error = await underwritingActions.updateUnderwritingDecision(_payload);

    if (error === null) {
      hideDialog();
      refresh();
      notify("Done");
    } else {
      setError(error ?? "Something went wrong");
    }
  }


  const handleLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLimit(value);
  }

  const handleAprChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setApr(value);
  }

  const handleRejectReasonsChange = (e: SelectChangeEvent<string[]>, _: any) => {
    e.preventDefault();

    const {
      target: { value },
    } = e;
    setRejectReasons(
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setComment(value);
  }

  const handleIsOffer = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setIsOffer(isSelect);
  }, []);

  const handleOfferCrossCell = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setOfferCrossSell(isSelect);
  }, []);

  const handleSendRejectionLetter = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setSendRejectionLetter(isSelect);
  }, []);

  const handleIsSyncOnboardingFlowWithProduct = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setIsSyncOnboardingFlowWithProduct(isSelect);
  }, []);

  const handleChangeProduct = useCallback((e: SelectChangeEvent<Product>) => {
    setProduct(e.target.value as Product);
  }, [product]);

  const handleChangeChangeTag = useCallback((e: SelectChangeEvent<UpdateChangeTag>) => {
    setChangeTag(e.target.value as UpdateChangeTag);
  }, [changeTag]);

  const title = decisionId === null
    ? "Update underwriting"
    : `Update underwriting for decision id ${decisionId}`

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title={title}
      onSend={handleSendClick}
      actionTitle="Update">

      <FormControl fullWidth>
        <InputLabel id="method-select-label-change">Change</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="method-select-label-change"
          value={changeTag}
          label="Product"
          onChange={handleChangeChangeTag}>
          <MenuItem value={"ChangeToApprove"}>ChangeToApprove</MenuItem>
          <MenuItem value={"ChangeToReject"}>ChangeToReject</MenuItem>
        </Select>
      </FormControl>

      {changeTag === "ChangeToApprove" &&
        <>

          <TextField
            fullWidth
            type="number"
            label="Limit (dollars)"
            value={limit}
            onChange={handleLimitChange}
            variant="outlined"
          />

          {
            product === "BalanceTransfer" &&
            <TextField
              fullWidth
              type="number"
              label="APR"
              value={apr}
              onChange={handleAprChange}
              variant="outlined"
            />
          }

          <FormControl fullWidth>
            <InputLabel id="method-select-label">product</InputLabel>
            <Select
              autoFocus={false}
              onFocus={() => { }}
              labelId="method-select-label"
              id="demo-simple-select"
              value={product}
              label="Product"
              onChange={handleChangeProduct}>
              <MenuItem value={"BalanceTransfer"}>BalanceTransfer</MenuItem>
              <MenuItem value={"BillPay"}>BillPay</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            // disabled={product === "BalanceTransfer"}
            control={<Checkbox checked={isOffer} onChange={handleIsOffer} />}
            label="isOffer" />

          <FormControlLabel
            control={
              <Checkbox checked={isSyncOnboardingFlowWithProduct}
                onChange={handleIsSyncOnboardingFlowWithProduct} />
            }
            label="isSyncOnboardingFlowWithProduct" />
        </>
      }

      {changeTag === "ChangeToReject" &&
        <>

          <FormControlLabel
            control={<Checkbox checked={sendRejectionLetter} onChange={handleSendRejectionLetter} />}
            label="sendRejectionLetter" />

          <FormControlLabel
            control={<Checkbox checked={offerCrossSell} onChange={handleOfferCrossCell} />}
            label="offer cross sell" />

          <FormControl fullWidth>
            <InputLabel id="method-select-label-reject">reject reasons</InputLabel>
            <Select
              autoFocus={false}
              onFocus={() => { }}
              labelId="method-select-label-reject"
              id="demo-simple-select"
              value={rejectReasons}
              label="Product"
              multiple
              renderValue={(selected) => selected.join(', ')}
              onChange={handleRejectReasonsChange}>
              {rejectReasonsValues.map((reason) =>
                <MenuItem
                  key={reason}
                  value={reason}>
                  <Checkbox checked={rejectReasons.indexOf(reason) > -1} />
                  <ListItemText primary={reason} />
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </>
      }

      <TextField
        fullWidth
        label="comment"
        value={comment}
        onChange={handleCommentChange}
        variant="outlined"
      />

      {
        error !== null &&
        <Alert color={"error"} severity="error">
          {error}
        </Alert>
      }

    </SimpleDialog>
  );
}

type Props = {
  payload: UpdateUnderwritingDialogPayload
  hideDialog: () => void
  userId?: string
}