import "./table-style.css";

import React, { ReactElement, useEffect } from 'react';
import { useListContext } from 'react-admin';
import { format } from 'date-fns'
import {
  Grid,
  GridColumn,
  GridColumnMenuSort,
  GridColumnMenuProps,
  GridDataStateChangeEvent,
  GridCellProps
} from '../lib/kendo-react-grid';
import {
  State as GridState
} from "../lib/kendo-data-query";
import {
  SortDescriptor
} from '../lib/kendo-data-query/dist/npm/sort-descriptor';
import { ColumnModel } from '../model/ColumnModel';
import { observer } from "mobx-react";
import { toJS } from 'mobx';
import currency from 'currency.js';
import { Box } from '@mui/material';
import { Total } from "./Total";

const sortsToOrders = (sorts: SortDescriptor[]): { [index: string]: string } => {
  const orders: { [index: string]: string } = {};

  for (const item of sorts) {
    orders[item.field] = item.dir!;
  }

  return orders;
}

export const Table: React.FC<Props> = observer((props) => {
  const {
    resource,
    savedFilters,
    onRowClick,
    rowActionBuilder,
    constantFilters = {},
    columnModel,
    noDataContent,
    idLink,
    customValueFormat,
    totalCalc
  } = props;
  const listData = useListContext(props);

  const { data, isLoading } = listData;

  const [gridState, setGridState] = React.useState<GridState>();
  const [savedSorts, setSavedSorts] = React.useState<SortDescriptor[]>([]);

  useEffect(() => {
    const savedSorts = localStorage.getItem(`sorts.${resource}`);
    if (savedSorts !== null) {
      setSavedSorts(JSON.parse(savedSorts));
    }
  }, [])

  useEffect(() => {
    setGridState({
      ...gridState,
      sort: savedSorts,
    })
  }, [savedSorts]);

  useEffect(() => {
    const order = sortsToOrders(savedSorts);
    const filters = { ...listData.filterValues, ...savedFilters, order, ...constantFilters, };
    listData.setFilters(filters, savedFilters);
  }, [savedFilters, constantFilters, savedSorts]);

  const getNewSort = (
    currentSort: SortDescriptor,
    allSorts: SortDescriptor[]
  ): SortDescriptor[] => {
    const existsIndex = (gridState?.sort ?? []).map((s) => s.field).indexOf(currentSort.field);
    if (existsIndex >= 0) {
      const newSort = [...allSorts];
      newSort[existsIndex] = currentSort;
      return newSort;
    }

    return [...allSorts, currentSort];
  }

  const persistSorts = (sorts: SortDescriptor[]) => {
    localStorage.setItem(`sorts.${resource}`, JSON.stringify(sorts));
  }

  const handleDataStateChange = ({ dataState }: GridDataStateChangeEvent) => {
    const { sort } = dataState;

    if (sort === undefined) {
      setGridState({ sort });
      return;
    }

    if (sort?.length === 0) {
      setSavedSorts([]);
      persistSorts([]);
      return;
    }

    const [newSortRaw] = sort;
    const newSort = getNewSort(newSortRaw, gridState?.sort ?? []);

    setSavedSorts(newSort);
    persistSorts(newSort);
  }

  const cellRender = (
    defaultRendering: React.ReactElement<HTMLTableCellElement, string | React.JSXElementConstructor<any>> | null,
    cellProps: GridCellProps,
  ) => {
    const { dataItem } = cellProps;
    const columnConfig = toJS(columnModel.columns).filter((v) => v.field === cellProps.field)[0];
    const value = cellProps.dataItem[cellProps.field!];

    if (customValueFormat !== undefined && Object.keys(customValueFormat).includes(cellProps?.field ?? '')) {
      return (
        <td className="table-column" aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {customValueFormat[cellProps.field!](value)}
        </td>
      )
    }

    if (columnConfig.format !== undefined && value !== null && value !== undefined) {
      return (
        <td className="table-column" aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {format(new Date(value), columnConfig.format)}
        </td>
      )
    }

    if (idLink !== undefined && value !== null && columnConfig.field === "id") {
      return (
        <td className="table-column" aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          <a style={{ textDecoration: "underline" }} href={idLink(dataItem)}>{value}</a>
        </td>
      );
    }

    if (columnConfig.isCents && value !== null && value !== undefined) {
      return (
        <td className="table-column" aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {currency(value, { fromCents: true }).format()}
        </td>
      );
    }

    if (cellProps.field === "actions" && rowActionBuilder !== undefined) {
      const action = rowActionBuilder(cellProps.dataItem);
      return (
        <td className="table-column" aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          {action}
        </td>
      )
    }

    return defaultRendering;
  }

  if (isLoading) {
    return <Box sx={{ height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      Loading...
    </Box>
  }

  if (data?.length === 0) {
    return noDataContent !== undefined
      ? noDataContent
      : (
        <Box sx={{ height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          No data
        </Box>
      )
  }

  const hasShowTotal = columnModel.columns.some((c) => c.showTotal);

  return (
    <>

      <Grid
        data={data}
        {...gridState}
        onColumnReorder={({ columns }) => columnModel.reorder(columns)}
        onColumnResize={(event) => {
          if (!event.end) return;
          columnModel.resize(event);
        }}
        onDataStateChange={handleDataStateChange}
        sortable
        resizable
        onRowClick={onRowClick}
        cellRender={cellRender}
        reorderable>
        {
          columnModel.columns.map((column) =>
            column.show && (<GridColumn
              className='table-column'
              key={column.field}
              orderIndex={column.orderIndex}
              field={column.field}
              title={column.title}
              width={column.width}
              columnMenu={
                props => <CustomColumnMenu {...props} />
              }
            />)
          )
        }
      </Grid>

      {
        hasShowTotal
        && <Box sx={{ paddingLeft: "10px" }}>
          {
            columnModel
              .columns.filter((c) => c.showTotal)
              .map((c) => <Total data={data} totalCalc={totalCalc} {...c} />)
          }
        </Box>
      }

    </>
  )
});

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuSort {...props} />
    </div>
  )
}

export type CustomValueFormat = { [index: string]: (value: any) => string };

export type RowClickEvent = {
  dataItem: any,
  nativeEvent: any,
}

export type RowActionBuilder = (record: { [index: string]: any }) => ReactElement

export type IdLink = (row: { [string: number]: any }) => string;

type Props = {
  resource: string
  savedFilters: { [index: string]: any }
  onRowClick?: (event: RowClickEvent) => void
  rowActionBuilder?: RowActionBuilder
  constantFilters?: { [index: string]: any }
  columnModel: ColumnModel
  noDataContent?: React.ReactElement
  idLink?: IdLink
  customValueFormat?: CustomValueFormat
  totalCalc?: { [index: string]: (data: any[]) => string }
}
