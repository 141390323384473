
import React, { FC, useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { Button, useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';
import currency from 'currency.js';
import { BTLimits } from '../../../network/BTActions';
import { LimitsCard } from './LimitsCard';

export type UpdateLimitDialogPayload = {
  userId: string
  updateCallback: () => void
} | null;

export const UpdateLimitDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { btActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [baseLimit, setBaseLimit] = useState<string>("");
  const [approvedLimit, setApprovedLimit] = useState<string>("");
  const [calcLimits, setCalcLimits] = useState<BTLimits | null>(null);

  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== undefined) {
      setApprovedLimit("");
      setBaseLimit("");
      setCalcLimits(null);
    }

    setError(null);
  }, [payload]);

  useEffect(() => {
    setError(null);
  }, [baseLimit, approvedLimit]);

  const onBaseLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setBaseLimit(value);
  }

  const onApprovedLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setApprovedLimit(value);
  }

  const handleSendClick = async () => {
    if (calcLimits === null) {
      notify("Calculate data before send");
      return;
    }

    if (payload === null) return;

    const userId = parseInt(payload?.userId ?? '');

    const error = await btActions.changeUserLimit({
      userId,
      approvedLimit: calcLimits.approvedLimit,
      validationsToIgnore: [],
      baseLimit: calcLimits.baseLimit,
      maxApprovedLimit: calcLimits.maxApprovedLimit,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      payload?.updateCallback();
      notify("Updated");
    }
  }

  const handleCalculate = async () => {
    if (baseLimit.length === 0 && approvedLimit.length === 0) return;

    const payload = baseLimit.length !== 0 ? {
      baseLimit: currency(baseLimit).intValue,
    } : {
      approvedLimit: currency(approvedLimit).intValue,
    }

    const limitsResponse = await btActions.calcUserLimitsDetails(payload);

    const { error, result } = limitsResponse;

    if (error !== null && error !== undefined) {
      setError(limitsResponse.error.message);
      return;
    }

    if (result !== null && result !== undefined) {
      setCalcLimits(result as BTLimits);
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update limits"
      actionTitle='Update'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="number"
        label="Base Limit (dollars)"
        value={baseLimit}
        onChange={onBaseLimitChange}
        onFocus={() => setApprovedLimit("")}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="number"
        label="Approved Limit (dollars)"
        value={approvedLimit}
        onChange={onApprovedLimitChange}
        onFocus={() => setBaseLimit("")}
        variant="outlined"
      />

      <Button
        sx={{ marginTop: "10px" }}
        disabled={baseLimit.length === 0 && approvedLimit.length === 0}
        label='Calculate'
        onClick={handleCalculate} />

      {calcLimits !== null &&
        <LimitsCard currentLimits={calcLimits} />
      }

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload?: UpdateLimitDialogPayload;
  hideDialog: () => void;
}