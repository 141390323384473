import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
// import { RowClickEvent } from "../../common/Table";
import { ColumnModel } from '../../model/ColumnModel';
import { RowClickEvent } from '../../common/Table';
import { Box } from '@mui/material';
import { ResourceVertical } from '../../common/ResourceVertical';

export const resource = "admin/generic-views/underwriting_user";

const tableColumns = [
  { field: "id", width: "80px", title: "id", show: true },
  { field: "name", width: "80px", title: "name", show: true },
  { field: "dob", width: "150px", title: "dob", show: true },
  { field: "city", width: "150px", title: "city", show: false },
  { field: "state", width: "80px", title: "state", show: false },
  { field: "zip", width: "80px", title: "zip", show: false },
  { field: "address", width: "200px", title: "address", show: false },
  { field: "user_address", width: "200px", title: "address", show: true, complex: ['address', 'city', 'zip', 'state',] },
  { field: "phone", width: "130px", title: "phone", show: true },
  { field: "income", width: "130px", title: "income", show: true, isCents: true },
  { field: "self_reported_income", width: "130px", title: "self_reported_income", show: true, isCents: true },
  { field: "self_reported_education_level", width: "130px", title: "self_reported_education_level", show: true },
  { field: "self_reported_credit_score", width: "130px", title: "self_reported_credit_score", show: true },
  { field: "email", width: "130px", title: "email", show: true },
  { field: "ssn (last 4)", width: "130px", title: "ssn (last 4)", show: true },
  { field: "balance_transfer_limit", width: "130px", title: "balance_transfer_limit", show: true },
  { field: "placid_bill_limit", width: "130px", title: "placid_bill_limit", show: true },
  { field: "created", width: "150px", title: "created", show: true, format: "yyyy-MM-dd" },
  { field: "is_primary_account_confirmed", width: "150px", title: "is_primary_account_confirmed", show: true, },
  { field: "deactivation_status", width: "150px", title: "deactivation_status", show: true, },
  { field: "credit_history_length", width: "150px", title: "credit history length", show: true, },
  { field: "last_seen", width: "150px", title: "last seen", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "latest_beh_model_result", width: "150px", title: "latest beh model result", show: true },

  // { field: "actions", width: "150px", title: "action", show: true },
];

export const UnderwritingUsersCollection: React.FC<Props> = ({ initialFilters, onRowClick }) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onRowClick === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { id } = dataItem;

    if (id === undefined) {
      throw Error('no user_id in row click event');
    }

    onRowClick(id);
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`User: ${initialFilters?.id.eq}`}
        </h4>
      </Box>

      <ResourceVertical
        initialFilters={initialFilters}
        resource={resource}
        // filters={filters}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        onRowClick={handleRowClick}
        columnModel={tableColumns}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}