import { Alert, Box, Button, Card } from "@mui/material"
import { FC, useEffect, useState } from "react"
import { useInjection } from "../../../di/useInjection";
import { BTLimits } from "../../../network/BTActions";
import { LimitsCard } from "./LimitsCard";
import { UpdateLimitDialog, UpdateLimitDialogPayload } from "./UpdateLimitDialog";

export const LimitLayout: FC<Props> = ({ userId }) => {
  const [currentLimits, setCurrentLimits] = useState<BTLimits | null>(null);
  const [currentLimitError, setCurrentLimitError] = useState<string | null>(null);
  const [updateLimitDialogPayload, setUpdateLimitDialogPayload] = useState<UpdateLimitDialogPayload>(null);

  const fetchLimits = (userId: number) => {
    if (userId !== undefined && userId !== null) {
      btActions.getUserLimitsDetails({ userId }).then((response) => {
        if (response.success) {
          console.log("initial limits", response.result as BTLimits);
          setCurrentLimits(response.result as BTLimits);
        } else {
          setCurrentLimitError(response.error.message);
        }
      });
    }
  }

  const handleUpdateClick = () => {
    setUpdateLimitDialogPayload({
      userId,
      updateCallback: () => { fetchLimits(parseInt(userId)); },
    });
  }

  const { btActions } = useInjection();

  useEffect(() => {
    const _userId = parseInt(userId);
    if (_userId !== undefined && _userId !== null) {
      btActions.getUserLimitsDetails({ userId: _userId }).then((response) => {
        if (response.success) {
          console.log("initial limits", response.result as BTLimits);
          setCurrentLimits(response.result as BTLimits);
        } else {
          setCurrentLimitError(response.error.message);
        }
      });
    }
  }, [userId]);

  return (
    <Card sx={{ padding: "20px" }}>
      <h4>Limits</h4>

      <UpdateLimitDialog
        payload={updateLimitDialogPayload}
        hideDialog={() => setUpdateLimitDialogPayload(null)}
      />

      {currentLimitError !== null && <Alert severity="info">{currentLimitError}</Alert>}

      {currentLimitError === null && currentLimits === null && <div>Loading...</div>}

      {currentLimitError === null
        && currentLimits !== null
        && <LimitsCard currentLimits={currentLimits} />
      }

      {currentLimitError === null
        && currentLimits !== null
        && <Button
          sx={{ marginTop: "10px" }}
          onClick={handleUpdateClick}
          size="medium">Update</Button>
      }

    </Card>
  )
}

type Props = {
  userId: string
}