import { useMemo, useState } from "react";
import { ChargesCollection } from "../../collections/charges-collection/ChargesCollection";
import { DebtCollection } from "../../collections/debt-collection/DebtCollection";
import { UserBankAccountsCollection } from "../../collections/user-bank-accounts-collection/UserBankAccountsCollection";
import { UserInfoCollection } from "../../collections/user-info-collection/UserInfoCollection";
import { SingleBillCyclesCollection } from "../../collections/single-bill-cycles-collection/SingleBillCyclesCollection";

const DebtDashboard = () => {
  const [userId, setUserId] = useState<string | undefined | null>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const debtCollection = useMemo(() => {
    return <DebtCollection
      onSet={setUserId}
      disableUrlFilterListener={isDrawerOpen} />
  }, [window.location.hash, isDrawerOpen]);

  const changeDrawerOpenHandler = (isOpen: boolean) => {
    window.location.hash = "";
    setIsDrawerOpen(isOpen);
  }

  return (
    <section className='dashboard'>
      <h1>Collections</h1>

      {debtCollection}

      {userId !== undefined && userId !== null &&
        <>
          <UserBankAccountsCollection
            initialFilters={{ "user_id": { "eq": userId } }}
            onDrawerOpenChange={changeDrawerOpenHandler} />
          <UserInfoCollection initialFilters={{ "user_id": { "eq": userId } }} />
          <SingleBillCyclesCollection initialFilters={{ "user_id": { "eq": userId } }} />
          <ChargesCollection initialFilters={{ "user_id": { "eq": userId } }} />
        </>
      }

    </section>
  );
}

export default DebtDashboard;
