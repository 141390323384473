import React, { useEffect } from 'react';
import { List, Pagination, ResourceContextProvider, } from 'react-admin';
import { CustomValueFormat, IdLink, RowActionBuilder, RowClickEvent, Table } from './Table';
import { getFiltersFromURI } from './helpers';
import { ColumnModel } from '../model/ColumnModel';
import { ColumnActions } from './ColumnActions';

export const Resource: React.FC<Props> = (props) => {
  const {
    resource,
    onRowClick,
    disableUrlFilterListener = true,
    filters,
    rowActionBuilder,
    tableActions,
    initialFilters = {},
    columnModel,
    noDataContent,
    idLink,
    customValueFormat,
    rowPerPageOptions,
    totalCalc,
  } = props;

  const [savedFilters, setSavedFilters] = React.useState<{ [index: string]: any }>({});

  const setFiltersFromURI = () => {
    if (disableUrlFilterListener) return;
    const filters = getFiltersFromURI();
    setSavedFilters(filters);
  }

  useEffect(() => {
    setFiltersFromURI();
  }, []);

  useEffect(() => {
    setFiltersFromURI();
  }, [window.location.hash]);

  const _tableActions = <ColumnActions {...props}>
    {tableActions}
  </ColumnActions>

  const perPage = rowPerPageOptions === undefined ? 10 : rowPerPageOptions[0];

  return (
    <ResourceContextProvider value={resource}>
      <List
        empty={false}
        disableSyncWithLocation
        filters={filters}
        filterDefaultValues={initialFilters}
        pagination={<Pagination rowsPerPageOptions={rowPerPageOptions ?? [10, 25, 50]} />}
        perPage={perPage}
        actions={_tableActions}>

        <Table
          resource={resource}
          savedFilters={savedFilters}
          onRowClick={onRowClick}
          rowActionBuilder={rowActionBuilder}
          constantFilters={initialFilters}
          columnModel={columnModel}
          noDataContent={noDataContent}
          idLink={idLink}
          customValueFormat={customValueFormat}
          totalCalc={totalCalc}
        />

      </List>
    </ResourceContextProvider>
  )
}

type Props = {
  resource: string
  disableUrlFilterListener?: boolean
  onRowClick?: (event: RowClickEvent) => void
  filters?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | undefined
  rowActionBuilder?: RowActionBuilder
  tableActions?: React.ReactNode
  initialFilters?: { [index: string]: any }
  columnModel: ColumnModel
  noDataContent?: React.ReactElement
  idLink?: IdLink
  customValueFormat?: CustomValueFormat
  rowPerPageOptions?: number[]
  totalCalc?: { [index: string]: (data: any[]) => string }
}
